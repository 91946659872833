import {config} from "@react-spring/web";
import useScrollTo from "react-spring-scroll-to-hook";
import Container from "./Container";
import SvgLogoBig from "../svg/SvgLogoBig";
import {useRouter} from "next/router";
import localized from "../locale/locales";
import {footerCols, socialLinks} from "../helpers/content";
import FooterCol from "./FooterCol";
import {useMemo, useState} from "react";

const classes = {
    dark: {
        wrapper: 'bg-black text-gray-300',
        link: 'text-white',
        line: 'bg-white/30',
        silent: 'text-white/50',
        border: 'border-white/30'
    },
    light: {
        wrapper: 'bg-white text-gray-600',
        link: 'text-black',
        line: 'bg-black/20',
        silent: 'text-black/30',
        border: 'border-black/20'
    }
}

export default function Footer({theme = 'dark', className = ''}) {
    const {locale} = useRouter();
    const lang = localized[locale];
    const {scrollTo} = useScrollTo(config.default);
    const [activeCol, setActiveCol] = useState(null);

    const themeClasses = useMemo(() => classes[theme], [theme]);

    const footerColsItems = useMemo(() => {
        return footerCols.map((col, colKey) => {
            const isActive = activeCol === colKey;
            return (
                <FooterCol title={lang.footer.columns[colKey]}
                           items={col}
                           className={`${themeClasses.link} ${themeClasses.border}`}
                           active={isActive}
                           setActive={() => isActive ? setActiveCol(null) : setActiveCol(colKey)}
                           key={`FooterCol: ${colKey}`}
                />
            )
        })
    }, [activeCol, lang.footer.columns, themeClasses]);

    return (
        <footer className={`py-12 ${themeClasses.wrapper}`}>
            <Container className={className}>
                <button onClick={() => scrollTo(0)} className={`text-0 ${themeClasses.link}`}>
                    <SvgLogoBig id="footer-logo" width={207} height={23}/> {lang.footer.back}
                </button>

                <div className="flex flex-col lg:flex-row gap-8 items-stretch">
                    <div className="flex-1 pt-7">
                        <p className="text-sm leading-loose max-w-[75ch] lg:max-w-[35ch]">
                            {lang.footer.description}
                        </p>
                    </div>

                    <div className="flex-[2] flex flex-col md:gap-8 md:flex-row justify-between">
                        {footerColsItems}
                    </div>
                </div>

                <div className={`mb-8 md:my-8 h-px w-full ${themeClasses.line}`}/>

                <div className="flex flex-col gap-6 lg:flex-row justify-between items-start text-sm">
                    <p>
                        {lang.footer.copyright.replace('{year}', new Date().getFullYear())}
                    </p>

                    <div className="flex items-center gap-1">
                        <a href={socialLinks.instagram}
                           className={`${themeClasses.link} mouse-hover:underline`}
                           target="_blank"
                           rel="nofollow noreferrer"
                        >
                            Instagram
                        </a>
                        <span>|</span>
                        <a href={socialLinks.facebook}
                           className={`${themeClasses.link} mouse-hover:underline`}
                           target="_blank"
                           rel="nofollow noreferrer"
                        >
                            Facebook
                        </a>
                    </div>
                </div>

                <div className={`mt-6 text-xs ${themeClasses.silent}`}>
                    <p dangerouslySetInnerHTML={{__html: lang.footer.recaptcha}}/>
                </div>
            </Container>
        </footer>
    )
}
